<template>
  <div class="erfvdfc">
    <!-- <div v-if="!flagImg">
      <div class="backsOut">
        <div class="backs">
          <img src="@/assets/images/fh.png" alt="" @click="goback" />
          <span>预览合同</span>
        </div>
      </div>
    </div> -->
    <div class="ewrfd" v-if="!flagImg">
      <van-loading class="rewfd" type="spinner" color="#1989fa" />
      <div class="shaode">请稍等,正在下载合同</div>
    </div>

    <!-- 预览合同 -->
    <div class="con_box" v-if="flagImg">
      <div class="swisn" v-for="(item, index) in imageList" :key="index">
        <img :src="item" alt="" />
      </div>
      <div class="btn">
        <van-button type="primary" size="large" @click="accomplish"
          >完成</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getContractImage } from "@/request/api";
export default {
  data() {
    return {
      url: "",
      imageList: [],
      contract: "",
      flagImg: false,
    };
  },
  created() {
    // 获取url后面的参数
    let data = {};
    let src = window.location.href;
    let index = src.indexOf("?");
    if (index === -1) {
      return data;
    }
    let dataStr = src.substring(src.indexOf("?") + 1);
    let dataArray = dataStr.split("&");
    for (let i = 0; i < dataArray.length; i++) {
      let param = dataArray[i].split("=");
      data[param[0]] = param[1];
    }
    console.log(data);
    this.contract = data;
    console.log(this.contract);

    // 倒计时

    this.lookImageUrl();
  },
  mounted() {},

  methods: {
    // 预览合同
    lookImageUrl() {
      console.log(this.contract);
      getContractImage({
        orderId: this.contract.orderId,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.imageList = res.data.imageList;
          this.flagImg = true;
        } else {
          console.log(res);
          this.$notify({
            type: "warning",
            message: res.message,
          });
          this.$router.push("/withholdList");
        }
      });
    },
    accomplish() {
      this.$router.push("/withholdList");
    },
    // goback() {
    //   this.$router.go(-1); //返回上一层
    // },
  },
};
</script>

<style lang="scss" scoped>
.intre {
  margin-top: 1rem;
  width: 100%;
  .btn {
    position: fixed;
    bottom: 0;
    margin: 0 auto;
  }
}
.erfvdfc {
  position: relative;
  height: 100vh;
}
/deep/ .van-field__label {
  width: 7rem;
}
.ewrfd {
  width: 12rem;
  height: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .rewfd {
    text-align: center;
  }
  .shaode {
    margin-top: 1rem;
    text-align: center;
  }
}
.con_box {
  .swisn {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}
</style>